<template>
    <div class="container" @click="openLink">
      <h1 class="title">{{ $t('main.demoSectionTitle') }}</h1>
      <h2 class="subtitle">{{ $t('main.demoSectionSubitle') }}</h2>
      <p class="user-short-id">
        wisher.pro/
        <span class="short-id">{{ shortId }}</span>
      </p>
    </div>
  </template>
  
  <script>
  import { analytics, logEvent } from '../main';

  export default {
    name: 'DemoWishlistView',
    data() {
      return {
        shortId: "wishr",
      };
    },
    methods: {
      openLink() {
        const url = `https://wisher.pro/${this.shortId}`;
        window.location.href = url;
        logEvent(analytics, 'demo_wishlist_button_click');
      },
    },
  };
  </script>
  
  <style scoped>
  .container {
    background-color: #fff;
    border-radius: 24px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    padding: 8px;
    margin-top: 16px;
    margin-right: 0;
    margin-bottom: 200px;
    margin-left: 0;
    width: 40%;
    max-width: 100%;
    text-align: center;
    cursor: pointer;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 12px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
  }
  
  .subtitle {
    font-size: 17px;
    margin-bottom: 32px;
    margin-right: 12px;
    margin-left: 12px;
    font-weight: normal;
    line-height: 1.4;
  }
  
  .user-short-id {
    font-size: 16px;
    margin-top: 12px;
    display: flex;
    justify-content: center;
    font-family: 'Source Code Pro', monospace;
  }
  
  .short-id {
    font-size: 15px;
    background-color: #CF7135;
    color: white;
    border-radius: 6px;
    padding: 1px 8px;
    margin-left: 4px;
    font-family: 'Source Code Pro', monospace;
  }
  
  @media (max-width: 767px) {
    .container {
      width: 80%;
    }
  }
  </style>
  